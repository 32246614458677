import * as m from '../../../../../src/paraglide/messages';

export class PaymentHandler {
	private formId: string;

	public setFormId(formId: string): void {
		this.formId = formId;
	}

	public async triggerPayment(): Promise<void> {
		if (!this.formId) {
			throw new Error(m.payment_form_missing());
		}

		const { url, params } = await this.retrievePaymentRedirectEndpoint();
		this.populatePaymentForm(url, params);
	}

	private async retrievePaymentRedirectEndpoint(): Promise<{
		url: string;
		params: Record<string, string>;
	}> {
		const response = await fetch('/api/seller/pay.php', { method: 'GET' });

		if (!response.ok) {
			throw new Error(m.payment_endpoint_unreachable());
		}

		const responseData = await response.json();

		if (!responseData.success) {
			throw new Error(responseData.message || m.payment_process_failed());
		}

		if (!responseData.redirect) {
			throw new Error(m.payment_redirect_missing());
		}

		return responseData.redirect;
	}

	private populatePaymentForm(url: string, params: Record<string, string>): void {
		const paymentForm = document.getElementById(this.formId) as HTMLFormElement;

		if (!paymentForm) {
			throw new Error(m.payment_form_missing());
		}

		paymentForm.action = url;
		paymentForm.method = 'POST';

		Object.entries(params).forEach(([key, value]) => {
			this.appendHiddenInput(paymentForm, key, value);
		});
	}

	private appendHiddenInput(paymentForm: HTMLFormElement, name: string, value: string): void {
		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = name;
		input.value = value;
		paymentForm.appendChild(input);
	}
}
