import { NewsletterResponse } from '../models/interfaces/general';

export class NewsletterService {
	async subscribeToNewsletter(email: string): Promise<NewsletterResponse> {
		try {
			const formData = this.buildFormData(email);
			const response = await this.sendRequest(formData);
			return this.handleResponse(response);
		} catch (error) {
			this.handleError(error);
			throw error;
		}
	}

	private buildFormData(email: string): URLSearchParams {
		const formData = new URLSearchParams();
		formData.append('type', 'newsletter');
		formData.append('email', email);
		formData.append('privacy', 'true');
		return formData;
	}

	private async sendRequest(formData: URLSearchParams): Promise<Response> {
		return await fetch('/api/sendMessage.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: formData.toString(),
		});
	}

	private async handleResponse(response: Response): Promise<NewsletterResponse> {
		if (!response.ok) {
			throw new Error('Response not ok');
		}

		const data = await response.json();

		if (!data.success) {
			throw new Error(data.message);
		}

		return data as NewsletterResponse;
	}

	private handleError(error: Error): void {
		console.error('Error subscribing to newsletter:', error.message);
	}
}
