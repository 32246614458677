import { AbstractFormHandler } from './abstractFormHandler';
import {
	SellerDataSchema,
	SellerDataEmailSchema,
	SellerFilesSchema,
	addressSchema,
	signInSchema,
} from '../../utils/validation';
import { ZodError } from 'zod';
import { Address, SellerData, SellerFiles } from '../../models/interfaces/general';
import { FormDataWrapper } from '../../utils/FormDataWrapper';
import { AuthService } from '../auth';

type SchemaDataUnion =
	| { schema: typeof SellerDataSchema; data: SellerData }
	| { schema: typeof addressSchema; data: Address }
	| { schema: typeof SellerFilesSchema; data: SellerFiles }
	| { schema: typeof signInSchema; data: { email: string; password: string } }
	| { schema: typeof SellerDataEmailSchema; data: { email: string } };

export class RegisterSellerFormHandler extends AbstractFormHandler {
	private authService: AuthService;

	constructor(authService: AuthService) {
		super();
		this.authService = authService;
	}

	public async submitForm(event: Event): Promise<void> {
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		this.removeAlerts(form);
		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			const sellerData = this.getFormData(form);
			const sellerAddress = this.getSellerAddress(form);
			const sellerFiles = this.getSellerFiles(form);

			const isLoggedIn = this.authService.isLoggedIn();

			await this.validateFormData(sellerData, sellerAddress, sellerFiles, isLoggedIn);

			if (!isLoggedIn) {
				const displayName = `${sellerData.legal_representative_name} ${sellerData.legal_representative_surname}`;
				await this.authService.singInOrSignUp(
					sellerData.email,
					sellerData.password,
					displayName,
				);
			}

			const response = await this.submitData(sellerData, sellerAddress);
			if (!response.success) {
				throw new Error(response.message);
			}

			if (sellerFiles.logo || sellerFiles.banner) {
				await this.uploadFilesSafely(sellerFiles, response.sellerId);
			}

			document.body.dispatchEvent(new CustomEvent('finish-step-1'));
		} catch (error) {
			this.handleFormError(form, error);
		} finally {
			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	private getFormData(form: HTMLFormElement): SellerData {
		const formDataWrapper = new FormDataWrapper(form);
		return {
			type: formDataWrapper.getString('type'),
			name: formDataWrapper.getString('name'),
			legal_representative_name: formDataWrapper.getString('legal_representative_name'),
			legal_representative_surname: formDataWrapper.getString('legal_representative_surname'),
			business_name: formDataWrapper.getString('business_name'),
			certified_id: formDataWrapper.getString('certified_id'),
			vat_number: formDataWrapper.getString('vat_number'),
			fiscal_code: formDataWrapper.getString('fiscal_code'),
			email: formDataWrapper.getString('email'),
			password: formDataWrapper.getString('password'),
			motivation: formDataWrapper.getNullableString('motivation'),
		};
	}

	private getSellerAddress(form: HTMLFormElement): Address {
		const formDataWrapper = new FormDataWrapper(form);
		return {
			address1: formDataWrapper.getString('address1'),
			address2: formDataWrapper.getNullableString('address2'),
			address3: formDataWrapper.getNullableString('address3'),
			state: formDataWrapper.getString('state'),
			city: formDataWrapper.getString('city'),
			postal_code: formDataWrapper.getString('postal_code'),
			address_type: null,
		};
	}

	private getSellerFiles(form: HTMLFormElement): SellerFiles {
		const formDataWrapper = new FormDataWrapper(form);
		return {
			logo: formDataWrapper.getNullableFile('logo'),
			banner: formDataWrapper.getNullableFile('banner'),
		};
	}

	private async validateFormData(
		sellerData: SellerData,
		sellerAddress: Address,
		sellerFiles: SellerFiles,
		isLoggedIn: boolean,
	): Promise<void> {
		const schemasToValidate: SchemaDataUnion[] = [
			{ schema: SellerDataSchema, data: sellerData },
			{ schema: addressSchema, data: sellerAddress },
			{ schema: SellerFilesSchema, data: sellerFiles },
		];

		if (!isLoggedIn) {
			schemasToValidate.push({
				schema: signInSchema,
				data: { email: sellerData.email, password: sellerData.password },
			});
		} else {
			schemasToValidate.push({
				schema: SellerDataEmailSchema,
				data: { email: sellerData.email },
			});
		}

		const zodError = await this.superValidate(...schemasToValidate);
		if (zodError.issues.length > 0) {
			throw zodError;
		}
	}

	private async submitData(
		sellerData: SellerData,
		sellerAddress: Address,
	): Promise<{ success: boolean; message: string; sellerId: number }> {
		const response = await fetch('/api/seller/create.php', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ sellerData, sellerAddress }),
		});

		return response.json();
	}

	private async uploadFilesSafely(sellerFiles: SellerFiles, sellerId: number): Promise<void> {
		try {
			await this.uploadFiles(sellerFiles, sellerId);
		} catch (error) {
			console.error('Error uploading files', error);
		}
	}

	private async uploadFiles(sellerFiles: SellerFiles, sellerId: number): Promise<void> {
		const formData = new FormData();
		if (sellerFiles.logo) formData.append('logo', sellerFiles.logo);
		if (sellerFiles.banner) formData.append('banner', sellerFiles.banner);
		formData.append('sellerId', sellerId.toString());

		const response = await fetch('/api/seller/upload.php', {
			method: 'POST',
			body: formData,
		});

		await response.json();
	}

	private handleFormError(form: HTMLFormElement, error: unknown): void {
		if (error instanceof ZodError) {
			this.createAlertForEachInput(form, error);
		} else {
			const errorMessage = this.extractErrorMessage(error);
			this.displayErrorMessage(form, errorMessage);
		}
	}
}
