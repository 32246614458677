import { FirebaseApp, initializeApp } from 'firebase/app';
import * as m from '../../../../src/paraglide/messages';
import type { ErrorMessages } from '../models/interfaces/general';
import { ZodIssue } from 'zod';

const firebaseConfig = {
	apiKey: process.env.FIREBASE_API_KEY,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.FIREBASE_DATABASE_URL,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.FIREBASE_APP_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const app: FirebaseApp = initializeApp(firebaseConfig);

const firebaseErrorMessages: ErrorMessages = {
	'auth/invalid-credential': m.auth_invalid_credentials(),
	'auth/wrong-password': m.auth_wrong_password(),
	'auth/invalid-email': m.auth_invalid_email(),
	'auth/user-disabled': m.auth_user_disabled(),
	'auth/too-many-requests': m.auth_too_many_requests(),
	'auth/user-not-found': m.user_not_found(),
	'auth/email-already-in-use': m.auth_email_already_in_use(),
	'auth/network-request-failed': m.auth_network_request_failed(),
	'auth/weak-password': m.auth_password_weak(),
	'auth/operation-not-allowed': m.auth_operation_not_allowed(),
	'auth/requires-recent-login': m.auth_requires_recent_login(),
	'auth/account-exists-with-different-credential':
		m.auth_account_exists_with_different_credential(),
	'auth/credential-already-in-use': m.auth_credential_already_in_use(),
	'auth/invalid-verification-code': m.auth_invalid_verification_code(),
	'auth/invalid-verification-id': m.auth_invalid_verification_id(),
	'auth/missing-email': m.auth_missing_email(),
	'auth/quota-exceeded': m.auth_quota_exceeded(),
};

export function getFirebaseErrorMessage(code: string): string {
	return firebaseErrorMessages[code] || m.unknown_error();
}

export function mapFieldNames(prefix: string | null, issues: ZodIssue[]): ZodIssue[] {
	if (!prefix) return issues;
	return issues.map((issue) => ({
		...issue,
		path:
			issue.path.length > 0
				? [`${prefix}_${issue.path[0]}`, ...issue.path.slice(1)]
				: issue.path,
	}));
}

export const max_upload_size = 10000000;
