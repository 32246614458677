import * as m from '../../../../src/paraglide/messages';
import { max_upload_size } from './general';
import { z } from 'zod';
import { AddressType, ContactType } from '../models/enums/general';
import CodiceFiscale from 'codice-fiscale-js';

const fileValidation = (file: File, ctx: z.RefinementCtx, fieldName: string) => {
	if (!file || !file.size) return;
	if (file.size > max_upload_size) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: m.field_name_file_too_big({
				fieldName: fieldName,
				maxUploadSize: 10,
			}),
		});
	} else if (!file.type.startsWith('image/')) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: m.field_name_not_an_image({ fieldName }),
		});
	}
};

export const signInSchema = z.object({
	email: z.string().trim().email(m.auth_invalid_email()),
	password: z.string().min(6, m.auth_invalid_password()).trim(),
});

export const signUpSchema = z.object({
	firstName: z.string().min(1, m.auth_invalid_name()).trim(),
	lastName: z.string().min(1, m.auth_invalid_surname()).trim(),
	email: z.string().trim().max(50, m.email_too_long()).email(m.auth_invalid_email()),
	password: z.string().min(6, m.auth_invalid_password()).trim(),
	privacy: z
		.string()
		.nullish()
		.refine((value: string | null | undefined) => value === 'on', m.invalid_privacy()),
});

export const resetPasswordSchema = z.object({
	email: z.string().email(m.auth_invalid_email()).trim(),
});

export const updateDetailsSchema = z.object({
	first_name: z.string().min(1, m.auth_invalid_name()).trim(),
	last_name: z.string().min(1, m.auth_invalid_surname()).trim(),
});

export const updatePasswordSchema = z.object({
	current_password: z.string().min(6, m.auth_invalid_password()).trim(),
	new_password: z.string().min(6, m.auth_invalid_password()).trim(),
	confirm_password: z.string().min(6, m.auth_invalid_password()).trim(),
});

export const checkoutSchema = z
	.object({
		certified_id: z
			.string()
			.optional()
			.nullish()
			.refine(
				(value: string | null | undefined) => (value ?? '').length <= 60,
				m.certified_id_too_long(),
			),
		vat_number: z.string().optional().nullish(),
		fiscal_code: z.string().optional().nullish(),
		email: z.string().trim().max(50, m.email_too_long()).email(m.auth_invalid_email()),
		company: z.string().optional().nullish(),
	})
	.superRefine(async (data, ctx) => {
		if (data.company) {
			if (!data.certified_id || data.certified_id.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_required(),
				});
			} else if (data.certified_id.trim().length > 60) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_too_long(),
				});
			}

			if (!data.vat_number || data.vat_number.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['vat_number'],
					message: m.vat_number_required(),
				});
			} else if (data.vat_number.trim().length > 20) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['vat_number'],
					message: m.vat_number_too_long(),
				});
			}
		} else {
			if (!data.fiscal_code || data.fiscal_code.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['fiscal_code'],
					message: m.fiscal_code_required(),
				});
			} else {
				try {
					const cf = new CodiceFiscale(data.fiscal_code);
					if (!cf.isValid()) {
						throw new Error('Invalid fiscal code');
					}
				} catch (e) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['fiscal_code'],
						message: m.fiscal_code_invalid(),
					});
				}
			}
		}
	});

export const userTaxInformationSchema = z
	.object({
		certified_id: z
			.string()
			.optional()
			.nullish()
			.refine(
				(value: string | null | undefined) => (value ?? '').length <= 60,
				m.certified_id_too_long(),
			),
		vat_number: z.string().optional().nullish(),
		fiscal_code: z.string().optional().nullish(),
		company: z.string().optional().nullish(),
	})
	.superRefine((data, ctx) => {
		//if company is not empty, certified_id is required
		if (data.company) {
			if (!data.certified_id || data.certified_id.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_required(),
				});
			} else if (data.certified_id.trim().length > 60) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_too_long(),
				});
			}

			//if company is not empty, vat_number is required
			if (!data.vat_number || data.vat_number.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['vat_number'],
					message: m.vat_number_required(),
				});
			} else if (data.vat_number.trim().length > 20) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['vat_number'],
					message: m.vat_number_too_long(),
				});
			}
		} else {
			//if company is empty, fiscal_code is required
			if (!data.fiscal_code || data.fiscal_code.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['fiscal_code'],
					message: m.fiscal_code_required(),
				});
			} else {
				try {
					const cf = new CodiceFiscale(data.fiscal_code);
					if (!cf.isValid()) {
						throw new Error('Invalid fiscal code');
					}
				} catch (e) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['fiscal_code'],
						message: m.fiscal_code_invalid(),
					});
				}
			}
		}
	});

export const addressSchema = z.object({
	address1: z.string().min(1, m.address_required()).max(255, m.address_too_long()).trim(),
	address2: z
		.string()
		.optional()
		.nullish()
		.refine((value) => !value || value.length <= 255, m.address_too_long()),
	address3: z
		.string()
		.optional()
		.nullish()
		.refine((value) => !value || value.length <= 255, m.address_too_long()),
	state: z.string().min(1, m.state_required()).max(100, m.state_too_long()).trim(),
	city: z.string().min(1, m.city_required()).max(100, m.city_too_long()).trim(),
	postal_code: z
		.string()
		.min(1, m.postal_code_required())
		.max(20, m.postal_code_too_long())
		.trim(),
});

const baseContactSchema = z.object({
	first_name: z.string().min(1, m.auth_invalid_name()).max(100, m.name_too_long()).trim(),
	last_name: z.string().min(1, m.auth_invalid_surname()).max(100, m.surname_too_long()).trim(),
	company: z
		.string()
		.optional()
		.nullish()
		.refine(
			(value: string | null | undefined) => (value ?? '').length <= 100,
			m.company_too_long(),
		),
	email: z.string().optional().nullish(),
	phone: z.string().min(1, m.phone_required()).max(25, m.phone_too_long()).trim(),
});

export const contactSchema = baseContactSchema.superRefine((data, ctx) => {
	if (data.email) {
		// Trim the email
		data.email = data.email.trim();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		// Check if email is too long
		if (data.email.length > 50) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['email'],
				message: m.email_too_long(),
			});
		}

		//check if email valid
		if (!emailRegex.test(data.email)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['email'],
				message: m.auth_invalid_email(),
			});
		}
	}
});

export const SellerDataSchema = z.object({
	type: z.string().min(1, m.seller_type_required()).max(100, m.seller_type_too_long()).trim(),
	name: z.string().min(1, m.seller_name_required()).max(100, m.seller_name_too_long()).trim(),
	legal_representative_name: z
		.string()
		.min(1, m.seller_legal_representative_name_required())
		.max(100, m.seller_legal_representative_name_too_long())
		.trim(),
	legal_representative_surname: z
		.string()
		.min(1, m.seller_legal_representative_surname_required())
		.max(100, m.seller_legal_representative_surname_too_long())
		.trim(),
	business_name: z
		.string()
		.min(1, m.business_name_required())
		.max(100, m.business_name_too_long())
		.trim(),
	fiscal_code: z
		.string()
		.min(1, m.fiscal_code_required())
		.max(16, m.fiscal_code_too_long())
		.trim(),
	vat_number: z.string().min(1, m.vat_number_required()).max(20, m.vat_number_too_long()).trim(),
	certified_id: z
		.string()
		.min(1, m.certified_id_required())
		.max(60, m.certified_id_too_long())
		.trim(),
	motivation: z.string().optional().nullish(),
});

export const SellerDataEmailSchema = z.object({
	email: z.string().trim().max(50, m.email_too_long()).email(m.auth_invalid_email()),
});

export const SellerFilesSchema = z.object({
	logo: z.any().superRefine((file, ctx) => {
		fileValidation(file, ctx, m.seller_logo_field_name());
	}),
	banner: z.any().superRefine((file, ctx) => {
		fileValidation(file, ctx, m.seller_banner_field_name());
	}),
});

export function isValidAddressType(value: string): value is AddressType {
	return Object.values(AddressType).includes(value as AddressType);
}

export function isValidContactType(value: string): value is ContactType {
	return Object.values(ContactType).includes(value as ContactType);
}
